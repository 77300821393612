import CardsHeaderFocus from 'components/cards/card-header-focus';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, Container, CustomInput, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { AppContext } from 'store/app.context';
import JobCard from './JobCard';
import JobsContainer from '../JobsContainer';
import useJobsService from 'services/jobs/jobs.service';
import { getQueryParamsFromObject } from 'utils';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './job-card.css';
import useSettingsService from 'services/settings/settings.service';
import Empty from 'components/Empty/Empty';
import { useHistory } from 'react-router-dom';
import { MISSION_STATUS } from 'config/core';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import useHomeService from 'services/home/home.service';

const JobBoard = () => {
  const history = useHistory();
  const { getNbNewJob } = useHomeService();
  const [useFilter, setUseFilter] = useState(true)
  const { jobsState, homeState, languageState, skillsState, userState } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState('1');
  const [skills, setSkills] = useState(localStorage.getItem("skills") ? JSON.parse(localStorage.getItem("skills")) : []);
  const [filters, setFilters] = useState(localStorage.getItem("filters") ? JSON.parse(localStorage.getItem("filters")) : {
    type: '',
    skills: skillsState && skillsState.mine && skillsState.mine.map((item) => item.id),
    tags: [],
    missionLocation: '',
    status: '',
    publicationDate: 'ALL_TIME',
    showUnread: false
  });
  const { getSkills, getMySkills } = useSettingsService();
  const [options, setOptions] = useState([]);
  const { getJobs, getJobsSaved, postViewJob, saveNewJob, deleteSaved } = useJobsService();
  const [jobs, setJobs] = useState([])
  const [jobSaved, setJobSaved] = useState([])
  const [modal, setModal] = useState(false)

  const postedInOptions = [
    { value: "LAST_WEEK", label: languageState.translation.JOBBOARD.LAST_WEEK },
    { value: "LAST_MONTH", label: languageState.translation.JOBBOARD.LAST_MONTH },
    { value: "ALL_TIME", label: languageState.translation.JOBBOARD.ALL_TIME }
  ]

  useEffect(() => {
    if (filters) {
      const reqData = getQueryParamsFromObject({ ...filters });
      getJobs(reqData);
      if (userState && userState.userInfo) {
        getJobsSaved(reqData)
      }
    }
  }, [filters]);

  useEffect(() => {
    getNbNewJob()
  }, [])

  const openDetails = (id,) => {
    if (userState && userState.userInfo) {
      postViewJob(id)
      localStorage.setItem('filters', JSON.stringify(filters))
      localStorage.setItem('skills', JSON.stringify(skills))
      history.push('/private/jobs/job-details/' + id)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    getSkills();
  }, [])

  useEffect(() => {
    if (userState && userState.userInfo) {
      getMySkills();
    }
  }, [userState])

  useEffect(() => {
    if (jobsState && jobsState.jobs) {
      setJobs(jobsState.jobs)
    }
    if (jobsState && jobsState.jobSaved) {
      setJobSaved(jobsState.jobSaved)
    }

  }, [jobsState]);

  useEffect(() => {
    if (localStorage.getItem('filters') && localStorage.getItem("skills")) {
      localStorage.getItem('filters') && setFilters(JSON.parse(localStorage.getItem('filters')))
      localStorage.getItem("skills") && setSkills(JSON.parse(localStorage.getItem('skills')) || [])
      setTimeout(() => {
        localStorage.removeItem('filters')
        localStorage.removeItem('skills')
      }, 4000)
    } else {
      if (skillsState && skillsState.skills) {
        setOptions(skillsState.skills.map(skill => ({
          value: skill.id,
          label: skill.key
        })));
      }
      if (skillsState.mine && useFilter) {
        setFilters({ ...filters, skills: skillsState.mine.map((item) => item.id) })
        setSkills([...skillsState.mine.map((item) => {
          return {
            value: item.id,
            label: item.key,
          };
        })]);
      } else {
        setFilters({
          type: '',
          skills: [],
          tags: [],
          missionLocation: '',
          status: '',
          publicationDate: 'LAST_WEEK',
        })
        setSkills([])
      }
    }
  }, [skillsState, useFilter])

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const saveJob = async (id) => {
    const result = await saveNewJob(id)
    if (result && result.status === 201) {
      const reqData = getQueryParamsFromObject({ ...filters, page: 1 });
      getJobs(reqData);
      getJobsSaved(reqData)
      toast.success(languageState.translation.ALERT.JOB_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  }

  const deleteSavedJob = async (id) => {
    const result = await deleteSaved(id)
    if (result && result.status === 200) {
      const reqData = getQueryParamsFromObject({ ...filters, page: 1 });
      getJobs(reqData);
      getJobsSaved(reqData)
      toast.success(languageState.translation.ALERT.DELETE_JOB_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  }

  return (
    <JobsContainer>
      <CardsHeaderFocus
        name={languageState.translation.COMMON.JOB_OVERVIEW}
        parentName="Dashboards"
        subName={`${homeState?.nbNewJobs} ${languageState.translation.COMMON.OPEN_POSITIONS}`}
      />
      <Container className="mt-3" fluid>
        <Row>
          {/* block filter */}
          <Col lg={12} className='d-flex align-items-center justify-content-between flex-wrap py-3'>
            <div>
              <h3 className='font-weight-bold'>
                {languageState.translation.JOBBOARD.FILTER_JOBBOARD_TITLE}
              </h3>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <span className='font-weight-bold mr-5'>{languageState.translation.JOBBOARD.USE_FILTER_LABEL}</span>
              <CustomInput
                type="switch"
                label={<Fragment>
                  <span className={`${useFilter ? 'yes-label' : 'no-label'} switch-icon-left text-capitalize`}>{useFilter ? languageState.translation.COMMON.YES : languageState.translation.COMMON.NO}</span>
                </Fragment>}
                className={`${useFilter ? 'switch-on' : 'switch-off'}`}
                id={'view_filter'}
                name={'view_filter'}
                inline
                checked={useFilter}
                onChange={(e) => setUseFilter(e.target.checked)}
              />
            </div>
          </Col>
          {useFilter && (
            <Col>
              <Card>
                <CardBody>
                  <Row className='m-0 gap-2 filter-jobboard align-items-baseline'>
                    <Col lg={12} className='p-0 d-flex gap-2 filter-jobboard-item align-items-baseline' >
                      <label className="mb-0 font-weight-bold width-250">
                        {languageState.translation.JOBBOARD.LOOKING_FOR}
                      </label>
                      <Select
                        options={[{ value: 'FULL_TIME', label: languageState.translation.COMMON.FULL_TIME }, { value: 'PART_TIME', label: languageState.translation.COMMON.PART_TIME }]}
                        isSearchable
                        isClearable
                        value={filters.type === 'FULL_TIME' ? { value: 'FULL_TIME', label: languageState.translation.COMMON.FULL_TIME } : filters.type === 'PART_TIME' ? { value: 'PART_TIME', label: languageState.translation.COMMON.PART_TIME } : null}
                        placeholder={languageState.translation.COMMON.MAKE_A_CHOICE}
                        onChange={(e) => setFilters({ ...filters, type: e && e.value })}
                      />
                    </Col>
                    <Col lg={12} className='p-0 d-flex gap-2 filter-jobboard-item align-items-baseline'>
                      <Label className="mb-0 font-weight-bold width-250">
                        {languageState.translation.JOBBOARD.USE_FOLLOWING_SKILLS}
                      </Label>
                      <Select
                        options={options}
                        isSearchable
                        isMulti
                        isClearable
                        value={skills}
                        placeholder={languageState.translation.COMMON.SKILLS_SELECT_PLACEHOLDER}
                        onChange={(e) => {
                          setSkills(e)
                          setFilters({ ...filters, skills: e && e.map(item => item.value) })
                        }}
                      />
                    </Col>
                    <Col lg={12} className='p-0 d-flex gap-2 filter-jobboard-item align-items-baseline'>
                      <Label className="mb-0 font-weight-bold width-250">
                        {languageState.translation.JOBBOARD.PUBLISHED_ON}
                      </Label>
                      <Select
                        options={postedInOptions}
                        isSearchable
                        isClearable
                        value={filters.publicationDate !== '' ? { value: filters.publicationDate, label: languageState.translation.JOBBOARD[filters.publicationDate] } : { value: 'LAST_WEEK', label: languageState.translation.JOBBOARD.LAST_WEEK }}
                        placeholder={`${languageState.translation.JOBBOARD.LAST_WEEK}`}
                        onChange={(e) => setFilters({ ...filters, publicationDate: e && e.value })}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12} className='d-flex align-items-center justify-content-between flex-wrap py-3' >
            <div>
              <h3 className='font-weight-bold'>
                {languageState.translation.JOBBOARD.RESULT_JOBS_FIRST_PARA} {activeTab === "1" ? jobsState?.config?.itemCount : jobSaved.length} {languageState.translation.JOBBOARD.RESULT_JOBS_SECOND_PARA}
              </h3>
            </div>
            <div className='d-flex align-items-center show-unread-filter justify-content-between'>
              <span className='font-weight-bold mr-5'>{languageState.translation.JOBBOARD.SHOW_UNREAD}</span>
              <CustomInput
                type="switch"
                label={<Fragment>
                  <span className={`${filters.showUnread ? 'yes-label' : 'no-label'} switch-icon-left text-capitalize`}>{filters.showUnread ? languageState.translation.COMMON.YES : languageState.translation.COMMON.NO}</span>
                </Fragment>}
                className={`${filters.showUnread ? 'switch-on' : 'switch-off'}`}
                id={'show_unread'}
                name={'show_unread'}
                inline
                checked={filters.showUnread}
                onChange={(e) => setFilters({ ...filters, showUnread: e.target.checked })}
              />
            </div>
          </Col>
          <Col lg={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === '1' }, 'cursor-pointer')}
                  onClick={() => toggle('1')}
                >
                  <i className="fas fa-list fa-sm mr-2" /> {languageState.translation.COMMON.JOB_LIST}
                </NavLink>
              </NavItem>
              {userState && userState.userInfo && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: activeTab === '2' }, 'cursor-pointer')}
                    onClick={() => toggle('2')}
                  >
                    <i className="fas fa-bookmark fa-sm mr-2" /> {languageState.translation.COMMON.SAVED_JOB}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Col className='px-0 mt-3'>
                  {jobs && jobs.length ? jobs.map((data, index) => {
                    return <Card key={`job-${index}`}>
                      <JobCard {...data} saveJob={saveJob} jobSaved={jobSaved && jobSaved.find(el => el.mission.id === data.id)} openDetails={openDetails} modal={modal} setModal={setModal} />
                    </Card>
                  }) : <Empty />}
                </Col>
              </TabPane>
              {userState && userState.userInfo && (
                <TabPane tabId="2">
                  <Col className='px-0 mt-3'>
                    {jobSaved && jobSaved.length ? jobSaved.map((data, index) => {
                      return <Card key={`job-${index}`}>
                        <JobCard {...data.mission} idSaved={data.id} savedAt={data.savedAt} saved deleteSavedJob={deleteSavedJob} openDetails={openDetails} modal={modal} setModal={setModal} />
                      </Card>
                    }) : <Empty />}
                  </Col>
                </TabPane>
              )}
            </TabContent>
          </Col>

        </Row>
      </Container>
    </JobsContainer>
  );
};

export default JobBoard;
