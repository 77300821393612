import React, { useContext, useEffect, useRef, useState } from 'react';
import HeaderRecruiter from 'components/Recruiter/HeaderRecruiter';
import { IMG_PREFIX, MODULES } from 'config/core';
import { Badge, Button, Card, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Media, Progress, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';
import { useHistory } from 'react-router-dom';
import useJobsService from 'services/jobs/jobs.service';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import useMissionService from 'services/missions/mission.service';
import APP_ROUTES from 'navigation/app.routes';
import './index.scss'
import Empty from 'components/Empty/Empty';
import _ from 'lodash';
import { DEFAULT_PRIVATE_PATH } from 'config/core';

const MissionApplicant = () => {
  const { dispatchSharedState, languageState, missionState } = useContext(AppContext);
  const { getAppliedUserByJobs, updateApplication, getCategories } = useJobsService()
  const { getMissionById } = useMissionService();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const intialData = useRef([])
  const [selectAllEnabled, setSelectAllEnabled] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const { id } = useParams()
  const [minSlider, setMinSlider] = useState(0);
  const [maxSlider, setMaxSlider] = useState(100);
  const [typeWork, setTypeWork] = useState([])
  const [experienceLevel, setExperienceLevel] = useState([])
  const [categories, setCategories] = useState([])
  const [serachKey, setSearchKey] = useState('')
  const [status, setStatus] = useState('ASC')
  const [profession, setProfession] = useState('ASC')
  const [workType, setWorkType] = useState('ASC')

  const onCheckUser = (user) => {
    let newChecked = [...checkedUsers];
    if (newChecked.includes(user.id)) {
      newChecked = newChecked.filter((item) => item !== user.id);
    } else {
      newChecked.push(user.id);
    }

    setCheckedUsers(newChecked);
  };

  useEffect(() => {
    if (id) {
      getMissionById(id);
      getCategories()
    }
    return () => {
      getMissionById(id);
    };
  }, []);
  useEffect(() => {
    getUsers(id, {
      type: typeWork,
      experienceLevel: experienceLevel,
      hourlyRateMin: minSlider,
      hourlyRateMax: maxSlider,
      categories: categories,
      serachKey: serachKey,
      limit: 8
    })
  }, [id, typeWork, experienceLevel, minSlider, maxSlider, categories, serachKey]);
  const getUsers = async (currentId, data) => {
    const result = await getAppliedUserByJobs(currentId, data)
    if (result) {
      intialData.current = result
      setUsers(result)
    }
  }
  const selectAll = () => {
    if (!selectAllEnabled) {
      const newChecked = [...checkedUsers];
      users.forEach((user) => {
        if (!checkedUsers.includes(user.id)) {
          newChecked.push(user.id);
        }
      });
      setCheckedUsers(newChecked);
    } else {
      setCheckedUsers([]);
    }
    setSelectAllEnabled(!selectAllEnabled);
  };

  useEffect(() => {
    dispatchSharedState({
      module: MODULES.MISSION_OVERVIEW,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);

  const handleApplication = async (jobId, idUser, status) => {
    const result = await updateApplication(jobId, { user: idUser, status })
    if (result) {
      toast.success(languageState.translation.ALERT.SUCCESS_UPDATE_APPLICANT, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      })
      getUsers(id)
    }
  }

  const setFilterCritiria = (value, setValue) => {
    setValue((prevValue) => {
      if ((prevValue || []).includes(value)) {
        return _.filter(prevValue, (el) => el !== value);
      }
      return [...(prevValue || []), value]
    })
  }

  return (
    <>
      <HeaderRecruiter namePage="APPLICANTS" />
      <div className='mission-applicant-page'>
        <Container fluid className="mt-4">
          <Row className='mission-applicant-content'>
            {/* block filter */}
            <Col className='col-12' lg={12} sm={12}>
              <Card className='p-4'>
                {!users?.length
                  ? <Empty icon="fas fa-user-friends" message={languageState.translation.COMMON.USER_SEARCH_NO_APPLICANTS} />
                  : <>
                    <CardHeader className="px-0 pb-4 pt-0 border-0 d-flex align-items-center">
                      <h3 className="mb-0">
                        {languageState.translation.COMMON.FREELANCERS_APPLICANTS_TITLE}
                      </h3>
                      {
                        users?.length
                          ? <div className="d-flex align-items-center ml-auto">
                            <label className="mt-1 mr-2">
                              {languageState.translation.COMMON.SEARCH}
                            </label>
                            <Input
                              type="text"
                              placeholder={languageState.translation.COMMON.SEARCH}
                              onChange={(e) => setSearchKey(e.target.value)}
                            />
                          </div>
                          : null
                      }

                    </CardHeader>
                    <div className=''>
                      <Table className="align-items-center table-flush " responsive>
                        <thead className="thead-light">
                          <tr>
                            <th className="">
                              <input
                                type='checkbox'
                                id={'table-check-all'}
                                className="custom-control-input"
                                checked={selectAllEnabled}
                                onChange={() => selectAll()}
                              />
                              <label className="custom-control-label"
                                htmlFor={'table-check-all'}></label>
                            </th>
                            <th className="sort">
                              {languageState.translation.COMMON.FREELANCER.toUpperCase()}
                            </th>
                            <th className="sort" onClick={() => {
                              if (profession === 'ASC') {
                                setProfession('DESC')
                                getUsers(id, {
                                  type: typeWork,
                                  experienceLevel: experienceLevel,
                                  hourlyRateMin: minSlider,
                                  hourlyRateMax: maxSlider,
                                  categories: categories,
                                  serachKey: serachKey,
                                  profession: 'DESC',
                                  limit: 8
                                })
                              } else {
                                setProfession('ASC')
                                getUsers(id, {
                                  type: typeWork,
                                  experienceLevel: experienceLevel,
                                  hourlyRateMin: minSlider,
                                  hourlyRateMax: maxSlider,
                                  categories: categories,
                                  serachKey: serachKey,
                                  profession: 'ASC',
                                  limit: 8
                                })
                              }
                            }} data-sort="profile">
                              {languageState.translation.COMMON.PROFILE}
                            </th>
                            <th className="sort" onClick={() => {
                              if (workType === 'ASC') {
                                setWorkType('DESC')
                                getUsers(id, {
                                  type: typeWork,
                                  experienceLevel: experienceLevel,
                                  hourlyRateMin: minSlider,
                                  hourlyRateMax: maxSlider,
                                  categories: categories,
                                  serachKey: serachKey,
                                  workType: 'DESC',
                                  limit: 8
                                })
                              } else {
                                setWorkType('ASC')
                                getUsers(id, {
                                  type: typeWork,
                                  experienceLevel: experienceLevel,
                                  hourlyRateMin: minSlider,
                                  hourlyRateMax: maxSlider,
                                  categories: categories,
                                  serachKey: serachKey,
                                  workType: 'ASC',
                                  limit: 8
                                })
                              }
                            }} data-sort='workType'>{languageState.translation.COMMON.TYPE}</th>
                            <th>{languageState.translation.COMMON.COMPLIANCY_INDICATOR}</th>
                            <th className="sort" onClick={() => {
                              if (status === 'ASC') {
                                setStatus('DESC')
                                getUsers(id, {
                                  type: typeWork,
                                  experienceLevel: experienceLevel,
                                  hourlyRateMin: minSlider,
                                  hourlyRateMax: maxSlider,
                                  categories: categories,
                                  serachKey: serachKey,
                                  status: 'DESC',
                                  limit: 8
                                })
                              } else {
                                setStatus('ASC')
                                getUsers(id, {
                                  type: typeWork,
                                  experienceLevel: experienceLevel,
                                  hourlyRateMin: minSlider,
                                  hourlyRateMax: maxSlider,
                                  categories: categories,
                                  serachKey: serachKey,
                                  status: 'ASC',
                                  limit: 8
                                })
                              }
                            }} data-sort="status">
                              {languageState.translation.COMMON.STATUS}
                            </th>
                            <th>{languageState.translation.COMMON.ACTIONS}</th>
                          </tr>
                        </thead>
                        <tbody className="list ">
                          {users &&
                            users.length > 0 &&
                            users.map((user, index) => {
                              return (<tr key={user.id}>
                                <td>
                                  <input
                                    className="custom-control-input"
                                    id={'table-check-all' + index}
                                    type="checkbox"
                                    checked={checkedUsers.includes(user.id)}
                                    onChange={() => onCheckUser(user)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={'table-check-all' + index}
                                  />
                                </td>
                                <th scope="row">
                                  <div className="d-flex align-items-center">
                                    <Media onClick={() =>
                                      history.push(`/private${APP_ROUTES.CONNECT.PROFILE_DETAILS}/${user?.id}`, { fromMission: true, user: user, id: id })
                                    } className="align-items-center c-pointer">
                                      <a
                                        className="avatar rounded-circle mr-3"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img
                                          alt="..."
                                          src={`${IMG_PREFIX}${user?.profileImage}`}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              require('assets/img/man.png').default;
                                          }}
                                        />
                                      </a>
                                      <Media>
                                        <span className="name mb-0 text-sm">
                                          {user?.firstName} {user?.lastName.charAt(0)}.
                                        </span>
                                      </Media>
                                    </Media>
                                  </div>
                                </th>
                                <td>{languageState.translation.STEPPER.FIRST_STEP_OPTIONS[user?.profession] || '----'}</td>
                                <td>{languageState.translation.COMMON[user?.workingTime]}</td>
                                <td>
                                  <Progress
                                    className="my-2"
                                    value={user?.application?.validatedRequirements?.length * (100 / missionState?.selectedMission?.requirements?.length)}
                                  >
                                    {user?.application?.validatedRequirements?.length * (100 / missionState?.selectedMission?.requirements?.length) || 0}%
                                  </Progress>
                                </td>
                                <td>
                                  <Badge className={`badge-dot ${user.status === 'APPLIED' ? 'bg-info-light' : user.status === 'SELECTED' ? 'bg-success-light' : user.status === 'INTERVIEWED' ? 'bg-primary-light' : user.status === 'REJECTED' ? 'bg-danger-light' : 'bg-warning-light'}`} color="">
                                    <i className={user.status === 'APPLIED' ? 'bg-info' : user?.status === 'SELECTED' ? 'bg-success' : user.status === 'INTERVIEWED' ? 'bg-primary' : user.status === 'REJECTED' ? 'bg-danger' : 'bg-warning'} />
                                    <span className="status">
                                      {user && user.status ? languageState.translation.COMMON.STATUS_LIST[user?.status] : languageState.translation.COMMON.STATUS_LIST.WAITING}
                                    </span>
                                  </Badge>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        handleApplication(id, user.id, 'REJECTED')
                                      }}
                                      outline
                                      className="action-icon"
                                    >
                                      <i className="fa fa-times" />
                                    </Button>
                                    <Button
                                      color="primary"
                                      outline
                                      className="action-icon"
                                      onClick={(e) =>
                                        history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
                                          user: user,
                                        })
                                      }
                                    >
                                      <i className="fa fa-comments" />
                                    </Button>
                                    <UncontrolledDropdown nav>
                                      <DropdownToggle
                                        className="nav-link px-0"
                                        color=""
                                        tag="a"
                                      >
                                        <Button className='action-icon' color="primary"><i className="fa fa-ellipsis-v" /></Button>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        {/* <DropdownItem
                                          className="noti-title hover_title"
                                          header
                                          tag="div"
                                        >
                                          <h5 className="text-overflow m-0"
                                            onClick={() => {
                                              handleApplication(id, user.id, 'INTERVIEWED')
                                            }}>
                                            {
                                              languageState.translation.RECRUITER
                                                .MISSIONS.INTERVIEW_USER
                                            }
                                          </h5>
                                        </DropdownItem> */}
                                        <DropdownItem
                                          className="noti-title hover_title"
                                          header
                                          tag="div"
                                        >
                                          <h5
                                            className="text-overflow m-0"
                                            onClick={() => {
                                              handleApplication(id, user.id, 'SELECTED')
                                            }}
                                          >
                                            {
                                              languageState.translation.RECRUITER
                                                .MISSIONS.ASSIGN_TO_MISSION
                                            }{' '}
                                          </h5>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </td>
                              </tr>)
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                }
              </Card>
              {/* <Row>
                <Col>
                  <AddFreelancer />
                </Col>
                <Col>
                  <InviteUser />
                </Col>
              </Row> */}
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
};

export default MissionApplicant;
