import { DEFAULT_PRIVATE_PATH } from 'config/core';
import { IMG_PREFIX } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import useInvitationService from 'services/invitations/invitation.service';
import { AppContext } from 'store/app.context';
import { isAuthorized } from 'utils';

const CardHeaderConnectFromMission = ({ name, iduser, user }) => {
    const { languageState, userState, missionState } = useContext(AppContext);
    const { sendInvitation, checkIfInvitationIsSent } = useInvitationService();
    const history = useHistory();
    const location = useLocation();
    const [invSent, SetInvSent] = useState();
    const [isInvitationSent, setIsInvSent] = useState(false);

    useEffect(() => {
        if (!isAuthorized()) return;
        checkifInvitation();
        return () => {
            // to change the title of browser page
            document.title = 'ExtraExpertise';
        }
    }, []);

    const checkifInvitation = async () => {
        const result = await checkIfInvitationIsSent(iduser);
        if (result && result.status === 200) {
            SetInvSent(result.data.status);
        }
    };

    const handleSendInivtation = async () => {
        if (!isAuthorized()) {
            history.push('/login');
            return;
        }
        const result = await sendInvitation(iduser);
        if (result && result.status === 201) {
            toast.success(languageState.translation.ALERT.SUCCESS_INVITATION, {
                position: 'bottom-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            setIsInvSent(true);
            checkifInvitation();
        }
    };

    return (
        <>
            <div className="header header-from-mission pb-6 rounded px-3 shadow">
                <Container
                    className="d-flex justify-content-between pt-7"
                    fluid
                >
                    <Col className="d-flex gap-2 align-items-center p-0" lg="9" md="8" sm="12">
                        <div className="rounded-img-container">
                            <img className="rounded-img-profile object-fit-cover"
                                src={
                                    user?.profileImage
                                        ? `${IMG_PREFIX}${user.profileImage}`
                                        : 'assets/img/man.png'
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = require('assets/img/man.png').default;
                                }}
                            />
                        </div>
                        <div>
                            <h1 className="display-2 mb-1 user-name line-height-30">{name}</h1>
                            
                            {
                                user.region
                                    ? <small className="text-white font-weight-700 d-flex align-items-center my-4" style={{ gap: 5 }}>
                                        {user.city
                                            ? <label>{`${user.city},`}</label>
                                            : null
                                        }
                                        <label>{user.region}</label>
                                    </small>: ''
                            }
                            {user?.workingTime && (
                                <p className="text-white mt-3">
                                    <span className="font-weight-bold font-small-4">{languageState.translation.COMMON.TYPE} :</span>{' '}
                                    <span className="font-weight-300 font-small-4">{user?.workingTime === 'FULL_TIME'
                                        ? 'Full Time'
                                        : 'Part Time'}{' '}
                                        </span>
                                        <span>{user?.profession ? languageState.translation.STEPPER.FIRST_STEP_OPTIONS[user.profession]:''}</span>
                                        

                                </p>
                            )}
                            {!history.location.pathname.includes('/public') ? ((userState?.userInfo?.kind === 'ADMIN') || (userState?.userInfo?.kind === 'COMPANY') || (userState.userInfo.id === user.id)) && user?.resume && (
                                <p className="text-white mt-2">
                                    <span className="font-weight-bold font-small-4">CV :</span>{' '}
                                    <Badge className='bg-primary-light'>
                                        <i className='fa fa-link mr-2' />
                                        <a className='text-primary' target='_blank' href={`${IMG_PREFIX}${user.resume}`}>{user.resume}</a>
                                    </Badge>
                                </p>
                            ) : null}
                            <Row className='mt-3 align-items-center gap-3 mx-0'>
                                <div className='d-flex align-items-center'>
                                    <i className='fa fa-check-circle text-white mr-2' />
                                    <p className='font-weight-300 font-small-4 text-white'>{location && location.state && location.state.user && location.state.user?.application?.validatedRequirements?.length * (100 / missionState?.selectedMission?.requirements?.length) || 0}% {languageState.translation.COMMON.COMPLIANT}</p>
                                </div>
                                {user && user.statusAviability ? (
                                    <div className='d-flex align-items-center flex-wrap'>
                                        <i className='fa fa-check-circle text-white mr-2' />
                                        <p className='font-weight-300 text-white'>{languageState.translation?.CONNECT[user.statusAviability]}</p>
                                    </div>
                                ) : null}
                                {/* {user && user.workingTime ? (
                                    <div className='d-flex align-items-center'>
                                        <i className='fa fa-check-circle text-white mr-2' />
                                        <p className='font-weight-300 text-white'>
                                            {languageState.translation.CONNECT.LOOKING_FOR} {user?.workingTime === 'FULL_TIME'
                                                ? 'Full Time'
                                                : 'Part Time'}{' '}
                                            {languageState.translation.COMMON.FREELANCER}</p>
                                    </div>
                                ) : null} */}
                            </Row>
                        </div>
                    </Col>
                    <Col className='d-flex flex-column justify-content-center p-0' lg="3" md="4" sm="12">
                        {userState?.userInfo?.id !== iduser &&
                            <div className="d-flex flex-column h-100 w-100 justify-content-center">
                                {isAuthorized() && (
                                    <Button
                                        className="mb-2"
                                        color={'secondary'}
                                        onClick={(e) =>
                                            history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
                                                user: user,
                                            })
                                        }
                                    >
                                        {languageState.translation.CONNECT.CHAT_WITH}
                                        {` ${name.toUpperCase()}`}
                                    </Button>
                                )}
                                {/* <Button
                                    className="mb-2"
                                    color={'secondary'}
                                    onClick={(e) => handleSendInivtation()}
                                    disabled={invSent === 'PENDING' || invSent === 'ACCEPTED' || userState?.userInfo?.id === iduser}
                                >
                                    {languageState.translation.COMMON.SAVE_JOB}
                                </Button> */}
                            </div>
                        }
                    </Col>
                </Container>
                <Modal isOpen={isInvitationSent} centered>
                    <ModalHeader>
                        <h3>{languageState.translation.CONNECT.CONNECTION_SENT}</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div
                                style={{
                                    lineHeight: '2rem',
                                    marginBottom: '1.5rem',
                                }}
                            >
                                {languageState.translation.CONNECT.WHY_CONNECT}
                            </div>
                            <div
                                style={{
                                    lineHeight: '2rem',
                                    marginBottom: '1.5rem',
                                }}
                            >
                                {languageState.translation.CONNECT.WHY_CONNECT_BODY}
                            </div>
                        </div>
                        <Button
                            color="primary"
                            onClick={() => history.push('/private/connect/network')}
                        >
                            {languageState.translation.CONNECT.LIST_OF_FREELANCERS}
                        </Button>
                        <Button onClick={() => setIsInvSent(false)}>
                            {languageState.translation.COMMON.CLOSE}
                        </Button>
                    </ModalBody>
                </Modal>
            </div >
        </>
    );
};

export default CardHeaderConnectFromMission;
